<template>


  <div>
      <b-row>
        <!--基本信息-->
        <b-col md="12">
          <div class="card">
            <h6 class="card-header  ">基本信息</h6>
            <div class="card-body">
              <b-row>
              <b-col md="4">


                  <b-form-group
                          label-cols="3"
                          label-cols-lg="3"
                          label="创建人"
                          label-for="creator"
                          label-size="sm"
                          class="mb-1 required"
                  >
                      <b-form-input
                              id="creator"
                              size="sm"
                              v-model="bom.creator_name"
                              readonly
                      />
                  </b-form-group>
              </b-col>

              <b-col md="4">
                  <b-form-group
                          label-cols="3"
                          label-cols-lg="3"
                          label="创建日期"
                          label-for="create_time"
                          label-size="sm"
                          class="mb-1 required"
                  >
                      <b-form-input
                              id="create_time"
                              size="sm"
                              v-model="bom.create_time"
                              readonly
                      />
                  </b-form-group>
              </b-col>
              </b-row>
            </div>
          </div>


        </b-col>

        <!--物料信息-->
        <b-col md="12">
          <div class="card">
            <h6 class="card-header  ">物料信息</h6>
            <div class="card-body">
                <b-table-simple
                  hover
                  small
                  responsive
                >
                  <b-thead head-variant="light">
                    <b-tr>
                      <b-th>科目</b-th>
                      <b-th>物料名称</b-th>
                      <b-th>69码</b-th>
                      <b-th>单份规格数量</b-th>
                      <b-th>数量</b-th>
                      <b-th>操作</b-th>
                    </b-tr>
                  </b-thead>

                  <b-tbody>
                     <!--主成品-->
                      <b-tr>
                       <b-th>主成品
                         <feather-icon
                                 icon="PlusSquareIcon"
                                 @click="showMainProduct()"
                                 size="21"
                                 v-if="isNumber(isChecked)===0"
                         />
                       </b-th>
                       <b-td>{{MainProductData.name}}</b-td>
                       <b-td>{{MainProductData.code}}</b-td>
                       <b-td>{{MainProductData.box_quantity}}</b-td>
                       <b-td>{{MainProductData.state}}</b-td>
                       <b-td></b-td>
                     </b-tr>
                    <!--副成品-->
                      <b-tr v-for="(item,index) in DeputyProductData" :key="index">
                      <b-th :rowspan="DeputyProductData.length" v-show="index==0">副成品
                          <feather-icon
                                  icon="PlusSquareIcon"
                                  @click="showDeputyProduct()"
                                  size="21"
                          />
                      </b-th>
                          <b-td>{{item.name}}</b-td>
                          <b-td>{{item.code}}</b-td>
                          <b-td>{{item.box_quantity}}</b-td>
                          <b-td>{{item.state}}</b-td>
                          <b-td v-if="item.product_id>0 && item.can_change===1"> <b-button @click="removeDeputyProductDataItem(index,DeputyProductData)" size="sm">
                              删除
                          </b-button></b-td>
                    </b-tr>
                    <!--主原料-->
                    <b-tr v-for="(item,index) in MainRawMaterialData" :key="'mainIndex-'+index">
                      <b-th :rowspan="MainRawMaterialData.length" v-show="index==0">主原料
                          <feather-icon
                                  icon="PlusSquareIcon"
                                  @click="showMainRawMaterial()"
                                  size="21"
                          />
                      </b-th>
                        <b-td>{{item.name}}</b-td>
                        <b-td>{{item.code}}</b-td>
                        <b-td>{{item.box_quantity}}</b-td>
                        <b-td>{{item.state}}</b-td>
                        <b-td v-if="item.product_id>0 && item.can_change===1"> <b-button @click="removeMainRawMaterialDataItem(index,MainRawMaterialData)" size="sm">
                            删除
                        </b-button></b-td>
                    </b-tr>
                  </b-tbody>


                </b-table-simple>
            </div>
          </div>


        </b-col>

        <!--备注附件操作-->
        <b-col md="12">
          <div class="card">
            <div class="card-body">
              <b-row>
                <b-col md="12">
                  <b-form-group
                          label-cols="1"
                          label-cols-lg="1"
                          label="备注"
                          label-for="remark"
                          label-size="sm"
                          class="mb-1"
                  >
                    <b-form-textarea
                            id="remark"
                            rows="3"
                            max-rows="6"
                            style="margin: 5px"
                            v-model="bom.remark"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                          label-cols="1"
                          label-cols-lg="1"
                          label="附件"
                          label-for="attachments"
                          label-size="sm"
                          class="mb-1"
                  >
                    <attachment-upload  v-if="bom.loaded"  :theme="'files'"
                                        :attachment_id="bom.attachments"
                                        :id="bom.attachments"
                                        :object_type="'bom'"
                                        :object_id="0"
                                        @change="onUploaded"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
            <div class="card-footer text-right ">
              <b-col
                      cols="12"
                      class="mt-50"
              >
                <b-button
                        variant="primary"
                        class="mr-1"
                        @click="save(1)"
                >
                  保存
                </b-button>

                <b-button
                        variant="primary"
                        class="mr-1"
                        @click="save(2)"
                >
                    提交
                </b-button>

                <b-button
                        variant="outline-secondary"
                        @click="cancel"
                >
                  取消
                </b-button>
              </b-col>
            </div>
          </div>
        </b-col>

      </b-row>
       <!--主成品-->
       <b-modal
            id="addMainProduct"
            ok-title="确认"
            cancel-title="取消"
            centered
            size="lg"
            @ok="addMainProduct"
            no-close-on-backdrop
            no-close-on-esc
            title="添加主成品"
            ref="main-product"
    >
      <product-select-main   ref="selectBomProductMain" :isSingle="isSingle" >

      </product-select-main>
    </b-modal>
       <!--副成品-->
       <b-modal
            id="addDeputyProduct"
            ok-title="确认"
            cancel-title="取消"
            centered
            size="lg"
            @ok="addDeputyProduct"
            no-close-on-backdrop
            no-close-on-esc
            title="添加副成品"
            ref="deputy-product"
    >
      <product-select-main   ref="selectBomProductDeputy" :isSingle="isSingle" >

      </product-select-main>
    </b-modal>
       <!--主原料-->
       <b-modal
            id="addMainRawMaterial"
            ok-title="确认"
            cancel-title="取消"
            centered
            size="lg"
            @ok="addMainRawMaterial"
            no-close-on-backdrop
            no-close-on-esc
            title="添加主原料"
            ref="main-raw-material"
    >
      <product-select-main   ref="selectBomMainRawMaterial" :isSingle="isSingle" :type="type" >

      </product-select-main>
    </b-modal>
    </div>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import bomStore from './bomStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
    toTime,
    toDate,
    getCodeOptions,
    getCode,
    getCodeLabel,
    getCodeColor,
    isEmpty,
    isNumber,
    second,
    removeOneParamsFromArr
} from '@core/utils/filter'
import ModalSelect from "@/views/components/modal/ModalSelect";
import AttachmentUpload from "@/views/apps/attachment/AttachmentUpload";
import ProductSelectMain from '@/views/apps/product/product-bom/ProductSelectMain'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
    ModalSelect,
    AttachmentUpload,
    ProductSelectMain

  },
  data() {
    return {
      id: ref(0),
      bom: ref({}),
      isSingle:'multi',  //product 默认多选
      MainProductData:'',
      DeputyProductData:[{
          product_id:null,
          name:''
      }],
      MainRawMaterialData:[{
          product_id:null,
          name:''
      }],
      type:'mainRawMaterial',
      isChecked:0,
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('bom')) store.registerModule('bom', bomStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('bom')) store.unregisterModule('bom')
    })

    const edit = function() {
      store.dispatch('bom/edit', {id: this.id}).then(res => {
        this.bom = res.data.data
        this.isChecked = this.bom.is_checked
        this.MainProductData = res.data.data.ext.mainProductData
        this.MainRawMaterialData = res.data.data.ext.mainRawMaterialData
        this.DeputyProductData = res.data.data.ext.deputyProductData
        this.bom.create_time = toTime(this.bom.create_time)
        this.bom.creator_name = getCodeLabel('user',this.bom.creator)
        if(isEmpty(this.MainRawMaterialData)){
            this.MainRawMaterialData = [{
                product_id:null,
                name:''
            }]
        }
        if(isEmpty(this.DeputyProductData)){
              this.DeputyProductData = [{
                  product_id:null,
                  name:''
              }]
          }
      })
    }

    const view = function() {
      store.dispatch('bom/view', {id: this.id}).then(res => {
        this.bom = res.data.data
      })
    }

    const cancel = function() {
      this.$router.go(-1)
    }

    const save = function(index) {
      // 取product_id
      let mainProductDataIds =      this.MainProductData.product_id
      let deputyProductDataCanDeleteIds =    getProductId(getCanChangeData(this.DeputyProductData))
      let deputyProductDataIds =             getProductId(getOtherData(this.DeputyProductData))
      let deputyByProductDataIds =             getByProductId(getOtherData(this.DeputyProductData))
      let mainRawMaterialDataIds =  getProductId(this.MainRawMaterialData)
      if(index==2) {
          if (isNumber(mainProductDataIds)===0 || isEmpty(mainRawMaterialDataIds)) {
              toast.error("主成品、副成品、主原料至少一条")
              return
          }
       }
       if(index==1){
           if (isNumber(mainProductDataIds)===0 ) {
               toast.error("至少选一个主成品!")
               return
           }
       }
        if(!isEmpty(deputyProductDataIds)){
            let otherData = getOtherData(this.DeputyProductData)
            for(let i = 0 ; i<otherData.length ; i ++){
                let byProductIds = otherData[i].by_product_id   //副成品关联的  主原料ids
                if(isEmpty(byProductIds)){
                    toast.error("副成品未关联主原料,刷新页面重新填写!")
                    return
                }
            }
        }

        this.bom.create_time = second(this.bom.create_time)
        store.dispatch('bom/save', {
                ...this.bom,
                mainProductDataIds:mainProductDataIds,
                deputyProductDataIds:deputyProductDataIds,
                mainRawMaterialDataIds:mainRawMaterialDataIds,
                deputyProductDataCanDeleteIds:deputyProductDataCanDeleteIds,
                deputyByProductDataIds:deputyByProductDataIds,
                index:index,
            }
        ).then( res =>{
            if (res.data.code === 0) {
                toast.success('数据已保存!')
                this.$router.push({name: 'apps-bom-list'});
            }else {
                this.bom.create_time = toTime(new Date().getTime() / 1000)
                toast.error('数据保存异常，'+ res.data.data)
            }
        })

    }

    const fromChildren = function (params, modal) {
      for (let i = 0; i < modal.length; i++) {
        this.bom[modal[i]] =params==null?null:params[modal[i]]
      }
      this.$forceUpdate()
    }
    //附件
    const onUploaded = function (id, attachment, result) {
      this.bom.attachments = result
    }
    /*----------------------主成品---------------*/
    const showMainProduct = function () {
      this.isSingle = 'single'
      this.$refs['main-product'].show()
    }
    const addMainProduct = function () {
      let data = this.$refs.selectBomProductMain.selected
      if(data!=null){
        this.MainProductData = data[0]
      }
    }
    /*----------------------主原料---------------*/
      const showMainRawMaterial = function () {
        this.isSingle = 'multi'
        this.$refs['main-raw-material'].show()
      }
      const addMainRawMaterial = function () {
        let data = this.$refs.selectBomMainRawMaterial.selected
        if (isNumber(data.length) !== 0) {
            this.DeputyProductData = removeDuplicates(this.DeputyProductData)
            for (let i = 0; i < data.length; i++) {
                //自动添加副成品 并去重    //如何判断
                let productId = isNumber(data[i].product_id)
                if(isNumber(this.DeputyProductData.length)>0){
                    let addResult = true ;
                    for(let j = 0 ; j < this.DeputyProductData.length ; j ++) {
                        if( isNumber(this.DeputyProductData[j].can_change) === 0){
                            if(this.DeputyProductData[j].by_product_id.length>0){
                                let arr = this.DeputyProductData[j].by_product_id.split(",");
                                if(arr.includes(productId.toString())){  //如果包含
                                    addResult = false ;
                                }
                            }
                        }
                    }
                    if (addResult){
                        let itemsProductId = data[i].ext.items.product_id
                         let isAdd = true ;
                        //判断 id如果已经存在副成品中 则将副成品的by_product_id 扩充  否则就新添一个副成品
                        for(let k = 0 ; k <this.DeputyProductData.length ; k ++){
                            if( isNumber(this.DeputyProductData[k].can_change) === 0){
                                if(this.DeputyProductData[k].product_id===itemsProductId){
                                 let arr =     this.DeputyProductData[k].by_product_id.split(",");
                                 arr.push(productId.toString())
                                 this.DeputyProductData[k].by_product_id = arr.toString()
                                    isAdd = false;
                                }
                            }
                        }
                         if(isAdd){
                             this.DeputyProductData.push(data[i].ext.items)
                         }
                    }
                }else{
                    this.DeputyProductData.push(data[i].ext.items)
                }
            }
            data.forEach(item =>{item.can_change = 1 })
            data.forEach(item =>{this.MainRawMaterialData.push(item)})
            this.MainRawMaterialData = removeDuplicates(this.MainRawMaterialData)
            this.DeputyProductData = removeDuplicates(this.DeputyProductData)
        }
          console.log("before delete")
          console.log(this.DeputyProductData)
      }
      const removeMainRawMaterialDataItem = function(index) {
              let newArr = []
              for (let i = 0; i < this.MainRawMaterialData.length; i++) {
                  if (i === index) {
                      //主原料移除  同时移除主原料自带副成品  需要判断是否存在两个主原料  共用一个副成品  存在则不删除副成品
                      let productId = this.MainRawMaterialData[i].product_id  //主原料id
                      for(let i = 0; i < this.DeputyProductData.length; i++) {
                          if(isNumber(this.DeputyProductData[i].can_change) === 0){  //不可删除的副成品
                              let byProductIds = this.DeputyProductData[i].by_product_id   //副成品关联的  主原料ids
                              if(isEmpty(byProductIds)){
                                  toast.error("副成品未关联主原料,联系管理员!")
                                  return
                              }
                              let byProductIdsArr = byProductIds.split(",")
                              if(byProductIdsArr.includes(productId.toString())&&byProductIdsArr.length>1){
                                  // 如果关联多个主原料   则去除
                                  let newArr =  removeOneParamsFromArr(byProductIdsArr,productId.toString())
                                  this.DeputyProductData[i].by_product_id = newArr.toString()
                                  continue
                              }

                              if(byProductIdsArr.includes(productId.toString())&&byProductIdsArr.length===1){
                                  //如果只关联了一个主原料  则删除
                                  let newArrT = []
                                  for (let i = 0; i < this.DeputyProductData.length; i++) {
                                      if (isEmpty(this.DeputyProductData[i].product_id)|(isNumber(this.DeputyProductData[i].by_product_id)===productId && isNumber(this.DeputyProductData[i].can_change)===0)) {
                                          continue
                                      }
                                      newArrT.push(this.DeputyProductData[i])
                                  }
                                  if(newArrT.length>0){
                                      this.DeputyProductData = newArrT
                                  }else{
                                      this.DeputyProductData = [{
                                          product_id:null,
                                          name:''
                                      }]
                                  }
                              }
                          }
                      }
                      continue
                  }
                  newArr.push(this.MainRawMaterialData[i])
              }
          if(newArr.length>0){
              this.MainRawMaterialData = newArr
          }else{
              this.MainRawMaterialData = [{
                  product_id:null,
                  name:''
              }]
          }

          console.log("after delete")
          console.log(this.DeputyProductData)

      }
        /*----------------------副成品---------------*/
      const showDeputyProduct = function () {
        this.isSingle = 'multi'
        this.$refs['deputy-product'].show()
      }
      const addDeputyProduct = function () {
        let data = this.$refs.selectBomProductDeputy.selected
          if (isNumber(data.length) !== 0) {
          data.forEach(item =>{item.can_change = 1 })
          data.forEach(item =>{this.DeputyProductData.push(item)})
            let newArr = []
            for (let i = 0; i < this.DeputyProductData.length; i++) {
                if (isEmpty(this.DeputyProductData[i].product_id)) {
                    continue
                }
                newArr.push(this.DeputyProductData[i])
            }
          newArr = removeDuplicates(newArr)
          this.DeputyProductData =   newArr
        }
      }

      const removeDeputyProductDataItem = function(index) {
          let a = this.DeputyProductData[index]
              let newArr = []
              for (let i = 0; i < this.DeputyProductData.length; i++) {
                  if (i === index) {
                      continue
                  }
                  newArr.push(this.DeputyProductData[i])
              }
              if(newArr.length>0){
                  this.DeputyProductData = newArr
              }else{
                  this.DeputyProductData = [{
                      product_id:null,
                      name:''
                  }]
              }
      }
      //去重
      const  removeDuplicates = function(params){
          params = params.filter(item=>isNumber(item.product_id)>0)
          if(params.length>0){
              for(let i=0;i<params.length;i++){
                  for(let j=i+1;j<params.length;j++){
                      if(params[i].product_id===params[j].product_id&&params[i].can_change===params[j].can_change){
                          params.splice(j,1);
                          j--;
                      }
                  }
              }
          }
          return params;
      }



      //获取product_id
      const getProductId = function (params) {
          let ids = "";
              if(params.length>0){
                  for(let i=0;i<params.length;i++){
                      if(params[i].product_id>0){
                          ids+=params[i].product_id+","
                      }
                  }
              }
          if (ids.length > 0) {
              ids = ids.substr(0,ids.length - 1);
          }
          return ids;
         }

      const getByProductId = function (params) {
          let ids = "";
          if(params.length>0){
              for(let i=0;i<params.length;i++){
                  if(isNumber(params[i].product_id)>0){
                      if(isNumber(params[i].by_product_id.length)>0){
                          ids+=params[i].by_product_id+"/"
                      }
                  }
              }
          }
          if (ids.length > 0) {
              ids = ids.substr(0,ids.length - 1);
          }
          return ids;
      }

      const getCanChangeData = function (params) {
            let newArr = []
            for (let i = 0; i < params.length; i++) {
                if(isNumber(params[i].can_change) == 1){
                    newArr.push(params[i])
                }
            }
            return newArr
        }

      const getOtherData = function (params) {
          let newArr = []
          for (let i = 0; i < params.length; i++) {
              if(isNumber(params[i].can_change) == 1){
                continue
              }else{
                  newArr.push(params[i])
              }

          }
          return newArr
      }

      return {
      edit,
      view,
      cancel,
      save,
          isNumber,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      fromChildren,
      onUploaded,
      showMainProduct,
      showMainRawMaterial,
      showDeputyProduct,
      addMainProduct,
      addDeputyProduct,
      addMainRawMaterial,
      removeDeputyProductDataItem,
      removeMainRawMaterialDataItem,
      removeDuplicates,
      getProductId,
      getCanChangeData,
      getOtherData,
      getByProductId,
          removeOneParamsFromArr,



    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>